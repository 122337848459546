$(document).ready(function() {

    var $main = $('main.offer');
    var $pageWrapper = $main.find('.pagination-wrapper');
    var $spinnerWrapper = $('.spinner-wrapper');
    var searchString = getUrlParamBrowser('q');
    var categoryString = getUrlParamBrowser('c');

    // Possible to be reuse for other pages...
    // CheckboxeParent
    $('.checkboxParent').click(function() {
        var $el = $(this);
        var lvl = $el.data('level');
        var $childCheckbox = $el.parent().siblings('ul').find('.checkboxChild[data-level="'+(lvl+1)+'"]');
        $childCheckbox.prop('checked', $el.is(':checked'));
        debounceStoresData();
    });

    // CheckboxeChild
    $('.checkboxChild').click(function() {
        var $el = $(this);
        var lvl = $el.data('level');
        var $parentCheckbox = $el.closest('ul').siblings('div').find('.checkboxParent[data-level="'+(lvl-1)+'"]');
        var countChild = $parentCheckbox.parent().siblings('ul').find('.checkboxChild[data-level="'+lvl+'"]').length;
        var countChildChecked = $parentCheckbox.parent().siblings('ul').find('.checkboxChild[data-level="'+lvl+'"]:checked').length;
        $parentCheckbox.prop('checked', countChildChecked > 0);
        $parentCheckbox.prop('indeterminate', countChildChecked > 0 && countChildChecked < countChild);
        debounceStoresData();
    });

    // Tenant Search Keyword
    var storeSearchFn = debounce(function(){
        debounceStoresData();
    }, 300);
    $main.find('.search-offer input').keydown(storeSearchFn);
    $main.find('select').change(function(){ debounceStoresData(); });

    // Display Settings
    $main.find('.display-settings .view-types button').click(function(e) {
        $this = $(this);
        $main.find('.display-settings .view-types button').removeClass('active');
        $this.addClass('active');
        var type = $this.attr('data-type');
        $main.find('.offer-container').addClass('hidden');
        $main.find('.offer-container[data-type="'+type+'"]').removeClass('hidden');
    });

    // Mobile apply button
    $('main.offer button.apply, main.offer button.clear, .reveal-overlay button.apply').click(function(e){
        var action = $(this).attr('data-action');
        if (action == 'viewby') {
            var type = $('#mobileViewBy input[name="viewby"]:checked').val();
            $main.find('.display-settings .view-types button').removeClass('active');
            $main.find('.display-settings .view-types button[data-type="'+type+'"]').addClass('active');
            $main.find('.offer-container').addClass('hidden');
            $main.find('.offer-container[data-type="'+type+'"]').removeClass('hidden');
        } else if (action == 'sortby') {
            var sortby = $('#mobileSortBy input[name="sortby"]:checked').val();
            $main.find('select').val(sortby);
            fetchAPI();
        } else if (action == 'clear') {
            clearSearch();
        } else {
            fetchAPI();
        }
        $('#categoriesMenu').foundation('close');
        $('#mobileViewBy, #mobileSortBy').foundation('close');
    });

    // Pagination
    $pageWrapper.find('button').click(function(e) {
        // console.log('pagination button click');
        $this = $(this);
        $pageWrapper.find('input').val($this.attr('data-page'));
        fetchAPI();
    });
    $pageWrapper.find('input').change(function(e) {
        // console.log('pagination input change');
        debounceStoresData();
    });

    // Fetch API
    var fetchAPI = function(){
        var categories = [];
        var keyword = $main.find('.search-offer input').val();
        var sortBy = $main.find('select').val();
        var currentPage = $pageWrapper.find('input').val();
        $main.find('.main-category[data-type="category"] input:checked').each(function(i, el) {
            categories.push($(el).val());
        });
        // console.log('debounceStoresData', keyword, sortBy, categories);

        if($('body').width() <= 767)
            sortBy = $('#mobileSortBy input[name="sortby"]:checked').val();

        $spinnerWrapper.removeClass('hidden');

        $.ajax({
            type: 'GET',
            url: '/api/offer/list',
            data: {
                'categories': categories,
                'search': keyword,
                'sort_by': sortBy,
                'page': currentPage
            },
            success: function(response) {
                console.log(response);
                var data = response.data;
                var metaPg = response.meta.pagination;
                var dateNow = (new Date()).toISOString();

                var $containerGrid = $('.offer-container[data-type="grid"]');

                $containerGrid.html('');

                if(data.length) {
                    $main.find('.pagination-wrapper').removeClass('hidden');
                    $main.find('.no-data-container').addClass('hidden');
                } else {
                    $main.find('.pagination-wrapper').addClass('hidden');
                    $main.find('.no-data-container').removeClass('hidden');
                }
                
                for (var i = 0; i < data.length; i++) {
                    // console.log(data[i]);
                    $templateClone = $('#offer-template-grid').clone().contents();
                    $templateClone.find('a').attr('href', data[i].url || '#');
                    $templateClone.find('source[type="image/webp"]').attr('srcset', data[i].imagewebp || '/assets/images/30.png');
                    $templateClone.find('source[type="image/jpeg"]').attr('srcset', data[i].image || '/assets/images/30.png');
                    $templateClone.find('img').attr('src', data[i].image || '/assets/images/30.png');
                    $templateClone.find('h5').html(data[i].title || 'Not set');
                    $templateClone.find('span').html(data[i].commonDuration || 'Not set');
                    var endDate = new Date((data[i].commonEndDate.date).replace(' ', 'T'));
                    endDate.setDate(endDate.getDate() + 1);
                    endDate = endDate.toISOString();
                    $templateClone.find('label').addClass(dateNow < endDate ? 'hidden' : '');
                    // $templateClone.addClass(data[i].commonPinFeature ? 'pinned' : '');
                    $containerGrid.append($templateClone);
                }

                // Pagination
                var pageCurrent = Number(metaPg.current_page);
                var pageTotal = Number(metaPg.total_pages);
                var pagePrev = metaPg.links.previous || 1;
                var pageNext = metaPg.links.next || pageTotal;

                $pageWrapper.find('input').val(pageCurrent);
                $pageWrapper.find('.total').text(pageTotal);

                $pageWrapper.find('button.previous').attr('data-page', pagePrev);
                $pageWrapper.find('button.next').attr('data-page', pageNext);

                $pageWrapper.find('button.previous').attr('disabled', !!(pagePrev == pageCurrent));
                $pageWrapper.find('button.next').attr('disabled', !!(pageNext == pageCurrent));
                $pageWrapper.find('input').attr('disabled', pageNext == 1)
                
                $spinnerWrapper.addClass('hidden');
                
                // console.log(pagePrev, pageNext, pageCurrent);
            },
            error: function(xhr) {
                console.log(xhr);
                $spinnerWrapper.addClass('hidden');
            }
        });
    };

    // Clear Search
    var clearSearch = function() {
        console.log('clearSearches :>> ');
        $('.checkboxParent').prop('checked',false).prop('indeterminate',false);
        $('.checkboxChild').prop('checked',false).prop('indeterminate',false);
        $main.find('.search-offer input').val('');
        $pageWrapper.find('input').val(1);
        fetchAPI();
    };

    // Apply Debounce to function
    var debounceDelay = 1000;
    var debounceStoresData = debounce(function() {
        // console.log('debounceStoresData');
        fetchAPI();
    }, debounceDelay);

    // Fetch data onload
    if($main.length) {
        setTimeout(function(){
            debounceStoresData();
        });
    }

    // Populate keyword to search input
    if(!!searchString) {
        console.log('url param : ', searchString);
        $main.find('.search-offer input').val(searchString);
    }
    
    // Programatically trigger click on checkbox
    if(!!categoryString) {
        if(categoryString.includes(',')) categoryString = categoryString.split(',');
        else categoryString = [categoryString];
        // console.log('url param : ', categoryString);
        categoryString.forEach(function(oneCategory){
            $main.find('input[data-slug="'+oneCategory+'"]').click();
        });
    } 
    // else {
    //     categoryString = ['specialty-anchor', 'mini-anchor', 'anchor'];
    //     categoryString.forEach(function(oneCategory){
    //         $main.find('input[data-slug="'+oneCategory+'"]').click();
    //     });
    // }

});