$(document).ready(function() {

    var $footer = $('footer');

    var $form = $footer.find('#newsletterForm');

    $.validator.addMethod("emailStrict", function(value, element) {
        var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

        return this.optional(element) || re.test(String(value).toLowerCase());
    }, "Please enter a valid email address.");

    $form.validate({
        rules: {
            email: {
                required:  {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                emailStrict: true
            },
        },
        messages: {
            email: {
                required: "Please enter a valid email address",
                emailStrict: "Invalid email address.",
            },
        },
    });

    $form.on('submit', function(e) {
        e.preventDefault();
        if ( $(this).data('validator').errorList.length > 0 ) {
            // console.log("Please complete the form.");
        } else {
            var data = $(this).serialize();
            var url = '/api/subscribe/newsletter';
            $footer.find('button.submit').attr('disabled', true);
            $footer.find('.spinner-mini-wrapper').removeClass('hidden');

            $.ajax({
                type: 'GET',
                url: url,
                data: data,
                success: function(response) {
                    // console.log(response);
                    var data = response.data;
                    $form[0].reset();
                    $('#newsletterFormSuccess').foundation('open');
                    $footer.find('button.submit').attr('disabled', false);
                    $footer.find('.spinner-mini-wrapper').addClass('hidden');
                },
                error: function(xhr) {
                    console.log(xhr);
                }
            });
        }
        return;
    });

});
