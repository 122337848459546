$(document).ready(function() {

    var $main = $('main.about');

    // if($main.length) { }

    var $form = $main.find('#contactUsForm');

    $main.find('#feedback').on('input propertychange', function() {
        enquiry_charLimit(this, 250, $main.find('#countdown_enquiry'));
    });

    $.validator.addMethod("emailStrict", function(value, element) {
        var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

        return this.optional(element) || re.test(String(value).toLowerCase());
    }, "Please enter a valid email address.");

    $.validator.addMethod("internationalNumber", function(value, element) {
        var re = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

        return this.optional(element) || re.test(String(value).toLowerCase());
    }, "Please enter a valid phone number.");


    $form.validate({
        rules: {
            title: {
                required: true,
            },
            name: {
                required: true,
            },
            email: {
                required:  {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                emailStrict: true
            },
            phone: {
                required:  {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                internationalNumber: true
            },
            type: {
                required: true,
            },
            feedback: {
                required: true,
                maxlength: 250
            },
            tnc: {
                required: true,
            }
        },
        messages: {
            title: "Please enter your title.",
            name: "Please enter your name.",
            email: {
                required: "Please enter a valid email address",
                emailStrict: "Invalid email address.",
            },
            phone: {
                required: "Please enter your contact number.",
            },
            type: {
                required: "Please select a enquiry type.",
            },
            feedback: {
                required: "Please enter your feedback.",
                maxlength: "250 characters is allow.",
            },
            tnc: {
                required: "Please make sure you have checked the check box.",
            },
        },
        errorPlacement: function(error, element) {
			if(element.attr("name") == "feedback") {
				error.insertAfter(element.next("small"));
			}else if(element.attr("name") == "tnc") {
				error.appendTo('#tnc_label');
			} else {
				error.insertAfter(element);
			}
		},
    });

    $form.on('submit', function(e) {
        e.preventDefault();
        if ( $(this).data('validator').errorList.length > 0 ) {
            // console.log("Please complete the form.");
        } else if( grecaptcha.getResponse() == "") {
            alert("Please complete the recaptcha.");
        } else {
            var data = $(this).serialize();
            $main.find('button.submit').attr('disabled', true);
            $main.find('.spinner-mini-wrapper').removeClass('hidden');

            // console.log(data);
            $.ajax({
                type: 'POST',
                url: '/wheelform/message/send',
                data: data,
                success: function(response) {
                    console.log(response);
                    var data = response.data;
                    $form[0].reset();
                    grecaptcha.reset();
                    $('#contactUsSuccess').foundation('open');
                    $main.find('button.submit').attr('disabled', false);
                    $main.find('.spinner-mini-wrapper').addClass('hidden');
                },
                error: function(xhr) {
                    console.log(xhr);
                }
            });
        }
        return;
    });
    
});

function enquiry_charLimit(input, maxChar, $el) {
	var len = $(input).val().length;
	if (len > maxChar) {
		$(input).val($(input).val().substring(0, maxChar));
		wordsRemaining = 0;
	} else{
		wordsRemaining = maxChar - len;
	}
 	$el.html(wordsRemaining);
}

function checkNumeric(o) {
    o.value = o.value.replace(/([^0-9])/g, "");
}