$(document).ready(function () {

  var $main = $('main.search-result');
  var $displaySettingBtns = $main.find('.display-settings button');
  var $pageWrapper = $main.find('.pagination-wrapper');
  var $spinnerWrapper = $('.spinner-wrapper');
  var searchString = getUrlParamBrowser('keyword');

  // Display Settings
  $displaySettingBtns.click(function (e) {
    $this = $(this);
    $displaySettingBtns.removeClass('active');
    $this.addClass('active');

    var type = $this.attr('data-type');
    $main.find('.search-container').addClass('hidden');
    $main.find('.search-container[data-type="' + type + '"]').removeClass('hidden');

    scrollTo($main.find('#keyword_wrappers'));

    // var type = $this.attr('data-type');
    // if (type == "allView") {
    //   $main.find('.search-container').removeClass('hidden');
    // } else {
    //   $main.find('.search-container').addClass('hidden');
    //   $main
    //     .find('.search-container[data-type="' + type + '"]')
    //     .removeClass('hidden');
    // }
  });

  $main.find('button.learn-more').click(function () {
    $this = $(this);
    var type = $this.attr('data-type');
    $displaySettingBtns.removeClass('active');
    $main.find('.display-settings button[data-type="' + type + '"]').addClass('active');
    $main.find('.search-container').addClass('hidden');
    $main.find('.search-container[data-type="' + type + '"]').removeClass('hidden');
    scrollTo($main.find('#keyword_wrappers'));
  });

  // Pagination
  $pageWrapper.find('button').click(function (e) {
    console.log('pagination button click');
    $this = $(this);
    $this.parents('.pagination-wrapper').find('input').val($this.attr('data-page'));
    var type = $this.parents('.search-container').attr('data-type');
    var limit = $this.parents('.pagination-wrapper').attr('data-limit');
    fetchAPI(type, limit);
  });
  $pageWrapper.find('input').change(function (e) {
    console.log('pagination input change');
    $this = $(this);
    var type = $this.parents('.search-container').attr('data-type');
    var limit = $this.parents('.pagination-wrapper').attr('data-limit');
    fetchAPI(type, limit, $this);
  });

  // Fetch API
  var fetchAPI = function (typeView, limit) {
    console.log('typeView :>> ', typeView);
    var typeStr = typeView.replace('View', '');
    $typePageWrapper = $('.search-container[data-type="' + typeView + '"]');
    var currentPage = $typePageWrapper.find('input').val();
    $spinnerWrapper.removeClass('hidden');

    $.ajax({
      type: 'GET',
      url: '/api/search-type',
      data: {
        'search': searchString,
        'type': typeStr,
        'limit': limit,
        'page': currentPage,
      },
      success: function (response) {
        console.log(response);
        var data = response.data;
        var metaPg = response.meta.pagination;
        var $container = $('.search-container[data-type="' + typeView + '"] .content-wrapper');
        var dateNow = (new Date()).toISOString();
        $container.html('');
        
        for (var i = 0; i < data.length; i++) {
          console.log(data[i]);
          var $templateClone = $('#search-template-' + typeStr).clone().contents();
          $templateClone.attr('href', data[i].url || '#');

          switch (typeView) {

            case 'offersView':
              $templateClone.find('img').attr('src', data[i].offerGallery || '/assets/images/30.png');
              $templateClone.find('h4 span').html(data[i].title || 'Not set');
              $templateClone.find('h4 small').html(data[i].commonDuration || '');
              var endDate = new Date((data[i].commonEndDate.date).replace(' ', 'T'));
              endDate.setDate(endDate.getDate() + 1);
              endDate = endDate.toISOString();
              $templateClone.find('label').addClass(dateNow < endDate ? 'hidden' : '');
              break;

            case 'eventsView':
              $templateClone.find('img').attr('src', data[i].eventGallery || '/assets/images/30.png');
              $templateClone.find('h4 span').html(data[i].title || 'Not set');
              $templateClone.find('h4 small').html(data[i].commonDuration || '');
              var endDate = new Date((data[i].commonEndDate.date).replace(' ', 'T'));
              endDate.setDate(endDate.getDate() + 1);
              endDate = endDate.toISOString();
              $templateClone.find('label').addClass(dateNow < endDate ? 'hidden' : '');
              break;

            case 'tenantsView':
              $templateClone.find('img').attr('src', data[i].logo || '/assets/images/30.png');
              $templateClone.find('label').addClass(data[i].label.value == 'none' ? 'hidden' : '').html(data[i].label.label);
              $templateClone.find('h4').html(data[i].title || 'Not set');
              break;

            case 'pressroomsView':
              $templateClone.find('a').attr('href', data[i].url || '#');
              $templateClone.find('img').attr('src', data[i].pressroomImage || '/assets/images/30.png');
              $templateClone.find('h4').html(data[i].title || 'Not set');
              $templateClone.find('.shortDesc').html(data[i].pressroomDescription || 'Not set');
              break;

            case 'featuresView':
              $templateClone.find('a').attr('href', data[i].url || '#');
              $templateClone.find('img').attr('src', data[i].featureImage || '/assets/images/30.png');
              $templateClone.find('h4').html(data[i].title || 'Not set');
              $templateClone.find('.shortDesc').html(data[i].featureDescription || 'Not set');
              break;

            default:
              break;
          }

          $container.append($templateClone);
        }

        // Pagination
        var pageCurrent = Number(metaPg.current_page);
        var pageTotal = Number(metaPg.total_pages);
        var pagePrev = metaPg.links.previous || 1;
        var pageNext = metaPg.links.next || pageTotal;

        $typePageWrapper.find('input').val(pageCurrent);
        $typePageWrapper.find('.total').text(pageTotal);

        $typePageWrapper.find('button.previous').attr('data-page', pagePrev);
        $typePageWrapper.find('button.next').attr('data-page', pageNext);

        $typePageWrapper.find('button.previous').attr('disabled', !!(pagePrev == pageCurrent));
        $typePageWrapper.find('button.next').attr('disabled', !!(pageNext == pageCurrent));

        $spinnerWrapper.addClass('hidden');
        scrollTo($main.find('#keyword_wrappers'));

        console.log(typeStr, pagePrev, pageNext, pageCurrent);
      },
      error: function (xhr) {
        console.log(xhr);
        $spinnerWrapper.addClass('hidden');
      }
    });
  };

});
