$(document).ready(function() {

    var $main = $('main.directory');

    var jsonData = function(levels) {
        // levels = levels.reverse(); // reverse
        var data = {
            "mapwidth": "1000",
            "mapheight": "500",
            "defaultstyle": "light",
            "styles": [
                {
                    "class": "light",
                    "hover": { "fill": "#f4f4f4" },
                    "active": { "fill": "#eacd98" }
                },
                {
                    "class": "dark",
                    "hover": { "fill": "#666" },
                    "active": { "fill": "#555" }
                }
            ],
            "categories": [
                {
                    "id": "nopointer-lotno",
                    "title": "Lot No.",
                    "toggle": "true",
                    "legend": "true",
                    "hide": "true"
                },
                {
                    "id": "nopointer-icons",
                    "title": "Icons",
                    "toggle": "true",
                    "legend": "true",
                    "hide": "true"
                }
            ],
            "levels": levels
        };
        // console.log(levels);
        return data;
    }
    
    var initMap = function(levels) {
        var map = $('#mapplic').mapplic({
            // source: '/assets/mapplic/mall.json',
            source: jsonData(levels),
            sidebar: true,
            sidebartoggle: true,
            alphabetic: true,
            developer: true,
            searchdescription: true,
            searcheverywhere: true,
            animations: true,
            minimap: false,
            marker: 'hidden',
            fillcolor: false,
            fullscreen: false,
            thumbholder: true,
            // deeplinking: false,
            maxscale: 5,
        });

        map.on('mapready', function(e, self) {
            setTimeout(function() {
                $('html,body').scrollTop(0);
            }, 100);
        });
        
    }

    var fetchData = function() {
        $.ajax({
            type: 'GET',
            // url: '/api/directories.json',
            url: '/api/directory/list',
            success: function(response) {
                console.log(response);
                initMap(response.data);
            },
            error: function(xhr) {
                console.log(xhr);
            }
        });
    }

    if($main.length) {
        fetchData();
    }
    
});
