// Define pageTitle use in GA
var pageTitle = document.title;
// console.log(pageTitle);

$(document).ready(function() {
    $(document).foundation();

    AOS.init();

    Modernizr.on('webp', function(result) {
        if (result) {

        } else {

        }
    });

    // Each slick
    $('.slick-slider').each(function(index) {
        $slick = $(this);
        // var arrowColor = !$slick.attr('data-arrow-color') ? '-white' : '-white';
        var arrowColor = '';
        // console.log($slick);
        var defaultOptions = {
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 1000,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            // adaptiveHeight: true,
            variableWidth: true,
            mobileFirst: true, // base as mobile, breakpoint up to make changes
            prevArrow:"<img class='slick-prev icon smaller' alt='Previous Arrow' src='/assets/images/icons/icon-chevron-left"+arrowColor+".svg'>",
            nextArrow:"<img class='slick-next icon smaller' alt='Next Arrow' src='/assets/images/icons/icon-chevron-right"+arrowColor+".svg'>",
            // responsive: [
            //     {
            //       breakpoint: 768,
            //       settings: {
            //         variableWidth: false
            //         // slidesToShow: 2,
            //         // slidesToScroll: 1
            //       }
            //     }
            // ],
        };
        var overwriteOptions = JSON.parse($slick.attr('data-options'));
        // console.log('overwriteOptions', overwriteOptions);
        var options = merge_options(defaultOptions, overwriteOptions);
        // console.log('options', options);
        $slick.slick(options);
    });

    
    // Scroll To Click
    $('.scrollTo').on('click', function(e) {
        e.preventDefault();
        var hash = $(this).attr('href');
        var $el = $(hash);
        var headerHeight = $('header').height();
        if($el.length) {
            $('html,body').animate({
                scrollTop: $(hash).offset().top - headerHeight
            }, 750);
        }
        return false;
    });


    // Auto go to tab if URL contains hash
    // ...


    // Sticky Item
    var $stickyItem = $('.sticky-item');
    if($stickyItem.length) {
        var stickyStopAt = $stickyItem.attr('data-stop-at');
        var windowHeight = $(window).height();
        
        var stickyNavFunc = function(){  
            var scrollTop = $(window).scrollTop();
            var stickyStopAtOffset = $(stickyStopAt).offset().top;
            // console.log(scrollTop, scrollTop + windowHeight, stickyStopAtOffset);
            if ((scrollTop + windowHeight) > stickyStopAtOffset) {
                $stickyItem.addClass('sticky-absolute');
            } else {
                $stickyItem.removeClass('sticky-absolute');   
            }  
        };  
     
        stickyNavFunc();  
     
        $(window).scroll(function() {  
            stickyNavFunc();  
        });  
    }

    // Initial Load
    $homeAnnouncement = $("#homeAnnouncement");
    if($homeAnnouncement.length) {
        setTimeout(function(){
            if(!sessionStorage.getItem("home_announcement_viewed")) {
                $homeAnnouncement.foundation('open');
                $homeAnnouncement.on('closed.zf.reveal', function(e){
                    sessionStorage.setItem("home_announcement_viewed", 1);
                });
            }
        }, 1000);
    }
    
});

// REMOVE DOUBLE SPACING ON ALL INPUTS
function filterInput(element) {
	// var removeDoubleSpacing = element.value.replace('  ', ' ');
	// element.value = removeDoubleSpacing;
}

function merge_options(obj1,obj2){
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
}

function getUrlParam (url, key) {
    // console.log(url, key);
    if(url == '') return;
    var url = new URL(url);
    return url.searchParams.get(key);
};

function getUrlParamBrowser (sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

// Debounce. To avoid spamming.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

function scrollTo($el) {
    if($el.length) {
        var headerHeight = $('header').height();
        $('html,body').animate({
            scrollTop: $el.offset().top - headerHeight
        }, 750);
    }
}