(function(document, window, config) {
    'use strict';

    var _config = config || {};
    var dataLayerName = 'dataLayer';

    var ga_loaded = false;
    window.addEventListener('load', function() {
        checkIfAnalyticsLoaded();
    }, false);

    function checkIfAnalyticsLoaded() {
        // log("Check");
        log(pageTitle);
        if (window.ga && ga.create && !ga_loaded) {
            ga_loaded = true;

            log("GA is loaded");

            initTrackEvent();

            if(_config.scroll)
              initScrollEvent();
        } else {
            // Retry. Probably want to cap the total number of times you call this.
            setTimeout(function() {
                checkIfAnalyticsLoaded();
            }, 500);
        }
    }

    function initTrackEvent() {
        log("Init Event Tracking");
        var types = _config.type;

        for (var i = 0; i < types.length; i++) {
            log("Type: " + types[i] + " included for tracking");
            if (types[i] == "a")
                $(types[i] + ":not(.button)").on("click", ClickLink);
            else if (types[i] == "a.button" || types[i] == "button" || types[i] == "input[type=submit]") {
                $(types[i]).on("click", ClickButton);
            }
        }
    }

    function initScrollEvent() {
      log("Init Scroll Tracking");

      var partial_views = [];
      $('[data-partial]').each(function() {
        partial_views.push({
          name: $(this).data("partial"),
          top: parseInt($(this).position().top),
          tracked: false
        });
        log($(this).data("partial") + ": " + parseInt($(this).position().top))
      });

      var urlsegment          = window.location.pathname.split('/');
      $(document).on("scroll", function() {
        for(var i = 0; i < partial_views.length; i++) {
          var current = partial_views[i];

          if($(this).scrollTop() >= current.top && !current.tracked) {
            current.tracked = true;
            TrackVirtualPageView(urlsegment[1] + "/virtual/" + current.name.replace(" ", "").toLowerCase(), current.name);
            log(partial_views);
            log($(this).scrollTop());
          }
        }

        var last = partial_views[partial_views.length - 1];
        var scrollTop           = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        var scrollHeight        = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        var scrolledToBottom    = (scrollTop + window.innerHeight) >= scrollHeight;

        if(scrolledToBottom && !last.tracked) {
          last.tracked = true;
          TrackVirtualPageView(urlsegment[1] + "/virtual/" + last.name.replace(" ", "").toLowerCase(), last.name);
          log(partial_views);
          log($(this).scrollTop());
        }
      });
    }

    function ClickLink(e) {
        var category = GetCategory($(this));

        var href = $(this).attr("href");
        var target = $(this).attr("target");
        var title = $(this).attr("data-title");

        var text = "";
        if ($(this).find("img").length > 0) {
            var $img = $(this).find("img");
            var attr = $img.attr('alt');

            if (typeof attr !== typeof undefined && attr !== false && attr.length > 0)
                text = $img.attr("alt");
            else
                text = $img.attr("src");
        } else {
            text = $(this).text();
        }

        var action = "Click Link";
        var label = title ? title : text;

        TrackEvent(category, action, label, 'GAEvent');
    }

    function ClickButton(e) {
        var button = $(e.target);
        var category = GetCategory($(this));
        var title = button.attr("data-title");

        var text = "";
        var $img = button.find("img");
        if ($img.length > 0) {
            var attr = $img.attr('alt');

            if (typeof attr !== typeof undefined && attr !== false && attr.length > 0)
                text = $img.attr("alt");
            else
                text = $img.attr("src");
        }
        else if (button.attr('value')) {
          text = button.val();
        }
        else {
            text = button.text();
        }

        var action = "Click Button";
        var label = title ? title : text;

        TrackEvent(category, action, label, 'GAEvent');
    }

    function GetCategory($item) {
        var category = "";

        if ($item.parents("header").length > 0 && $item.parents("nav").length > 0)
            category = "Header";
        else if ($item.parents("footer").length > 0)
            category = "Footer";
        else if ($item.parents("aside").length > 0)
            category = "Cross Sell";
        else if ($item.parents("article").length > 0)
            category = "Article";
        else if ($item.parents("main").length > 0)
            category = "Body";
        else
            category = "Body";
        //category = "Unknown";

        return category;
    }

    function TrackVirtualPageView(url, title) {
        var _ga = window.GoogleAnalyticsObject;
        var category = category + " | " + pageTitle;
        title = pageTitle;

        if (typeof window[dataLayerName] !== 'undefined') {
            window[dataLayerName].push({
                event: "VirtualPageview",
                virtualPageTitle: title,
                virtualPageURL: url
            });
        } else if (typeof window[_ga] === 'function' &&
            typeof window[_ga].getAll === 'function') {
            window[_ga]('send', {
                hitType: 'pageview',
                page: url,
                title: title
            });
        } else if (typeof window._gaq !== 'undefined') {
            window._gaq.push(['_trackPageview', url]);
        }

        log('Track Virtual PageView: ' + title + '(' + url + ')');
    }

    function TrackEvent(category, action, label) {
        var _ga = window.GoogleAnalyticsObject;
        var category = category + " | " + pageTitle;

        if (typeof window[dataLayerName] !== 'undefined') {
            if (event == "VirtualPageview") {
                window[dataLayerName].push({
                    event: "VirtualPageview",
                    virtualPageTitle: category,
                    virtualPageURL: action
                });
            } else {
                window[dataLayerName].push({
                    event: "GAEvent",
                    eventCategory: category,
                    eventLabel: label,
                    eventAction: action
                });
            }
        } else if (typeof window[_ga] === 'function' &&
            typeof window[_ga].getAll === 'function') {
            window[_ga]('send', {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                eventLabel: label
            });
        } else if (typeof window._gaq !== 'undefined') {
            window._gaq.push(['_trackEvent', category, action, label]);
        }

        log('Track Event: ' + category + '(' + action + ' | ' + label + ')');
    }

    function log(ex) {
        if (_config.debug)
            console.log(ex);
    }

})(document, window, {
    debug: false,
    type: [
        'a',
        'a.button',
        'button',
        'input[type=submit]'
    ],
    scroll: false
});
