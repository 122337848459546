$(document).ready(function() {

    var $main = $('main.tenant');
    var $pageWrapper = $main.find('.pagination-wrapper');
    var $spinnerWrapper = $('.spinner-wrapper');
    var searchString = getUrlParamBrowser('q');
    var categoryString = getUrlParamBrowser('c');

    // Possible to be reuse for other pages...
    // CheckboxeParent
    $('.checkboxParent').click(function() {
        var $el = $(this);
        var lvl = $el.data('level');
        var $childCheckbox = $el.parent().siblings('ul').find('.checkboxChild[data-level="'+(lvl+1)+'"]');
        $childCheckbox.prop('checked', $el.is(':checked'));
        debounceStoresData();
    });

    // CheckboxeChild
    $('.checkboxChild').click(function() {
        var $el = $(this);
        var lvl = $el.data('level');
        var $parentCheckbox = $el.closest('ul').siblings('div').find('.checkboxParent[data-level="'+(lvl-1)+'"]');
        var countChild = $parentCheckbox.parent().siblings('ul').find('.checkboxChild[data-level="'+lvl+'"]').length;
        var countChildChecked = $parentCheckbox.parent().siblings('ul').find('.checkboxChild[data-level="'+lvl+'"]:checked').length;
        $parentCheckbox.prop('checked', countChildChecked > 0);
        $parentCheckbox.prop('indeterminate', countChildChecked > 0 && countChildChecked < countChild);
        debounceStoresData();
    });

    // Tenant Search Keyword
    var storeSearchFn = debounce(function(){
        debounceStoresData();
    }, 300);
    $main.find('.search-tenant input').keydown(storeSearchFn);
    $main.find('select').change(function(){ debounceStoresData(); });

    // Display Settings
    $main.find('.display-settings .view-types button').click(function(e) {
        $this = $(this);
        $main.find('.display-settings .view-types button').removeClass('active');
        $this.addClass('active');
        var type = $this.attr('data-type');
        $main.find('.tenant-container').addClass('hidden');
        $main.find('.tenant-container[data-type="'+type+'"]').removeClass('hidden');
    });

    // Mobile apply button
    $('main.tenant button.apply, main.tenant button.clear, .reveal-overlay button.apply').click(function(e){
        var action = $(this).attr('data-action');
        if (action == 'viewby') {
            var type = $('#mobileViewBy input[name="viewby"]:checked').val();
            $main.find('.display-settings .view-types button').removeClass('active');
            $main.find('.display-settings .view-types button[data-type="'+type+'"]').addClass('active');
            $main.find('.tenant-container').addClass('hidden');
            $main.find('.tenant-container[data-type="'+type+'"]').removeClass('hidden');
        } else if (action == 'sortby') {
            var sortby = $('#mobileSortBy input[name="sortby"]:checked').val();
            $main.find('select').val(sortby);
            fetchAPI();
        } else if (action == 'clear') {
            clearSearch();
        } else {
            fetchAPI();
        }
        $('#categoriesMenu').foundation('close');
        $('#mobileViewBy, #mobileSortBy').foundation('close');
    });

    // Pagination
    $pageWrapper.find('button').click(function(e) {
        // console.log('pagination button click');
        $this = $(this);
        $pageWrapper.find('input').val($this.attr('data-page'));
        fetchAPI();
    });
    $pageWrapper.find('input').change(function(e) {
        // console.log('pagination input change');
        debounceStoresData();
    });

    // Fetch API
    var fetchAPI = function(){
        var categories = [];
        var levels = [];
        var keyword = $main.find('.search-tenant input').val();
        var sortBy = $main.find('select').val();
        var currentPage = $pageWrapper.find('input').val();
        $main.find('.main-category[data-type="category"] input:checked').each(function(i, el) {
            categories.push($(el).val());
        });
        $main.find('.main-category[data-type="level"] input:checked').each(function(i, el) {
            levels.push($(el).val());
        });
        // console.log('debounceStoresData', keyword, sortBy, categories, levels);

        if($('body').width() <= 767)
            sortBy = $('#mobileSortBy input[name="sortby"]:checked').val();

        $spinnerWrapper.removeClass('hidden');

        $.ajax({
            type: 'GET',
            url: '/api/store/list?page='+currentPage,
            data: {
                'categories': categories,
                'levels': levels,
                'search': keyword,
                'sort_by': sortBy
            },
            success: function(response) {
                console.log(response);
                var data = response.data;
                var metaPg = response.meta.pagination;

                var $containerGrid = $('.tenant-container[data-type="grid"]');
                var $containerList = $('.tenant-container[data-type="list"] .content');

                $containerGrid.html('');
                $containerList.html('');

                if(data.length) {
                    $main.find('.pagination-wrapper').removeClass('hidden');
                    $main.find('.no-data-container').addClass('hidden');
                } else {
                    $main.find('.pagination-wrapper').addClass('hidden');
                    $main.find('.no-data-container').removeClass('hidden');
                }
                
                for (var i = 0; i < data.length; i++) {
                    // console.log(data[i]);
                    $templateGridClone = $('#tenant-template-grid').clone().contents();
                    $templateGridClone.find('a').attr('href', data[i].url || '#');
                    $templateGridClone.find('source[type="image/webp"]').attr('srcset', data[i].logowebp || '/assets/images/30.png');
                    $templateGridClone.find('source[type="image/jpeg"]').attr('srcset', data[i].logo || '/assets/images/30.png');
                    $templateGridClone.find('img').attr('src', data[i].logo || '/assets/images/30.png');
                    $templateGridClone.find('label').addClass(data[i].label.value == 'none' ? 'hidden' : '').html(data[i].label.label);
                    $templateGridClone.find('h5').html(data[i].title || 'Not set');
                    $templateGridClone.find('span').html(data[i].location || 'Not set');
                    $containerGrid.append($templateGridClone);
                    
                    $templateListClone = $('#tenant-template-list').clone().contents();
                    $templateListClone.find('a').attr('href', data[i].url || '#');
                    $templateListClone.find('h4').html(data[i].title || 'Not set');
                    $templateListClone.find('span.level').html(data[i].operatingHour || 'Not set');
                    $templateListClone.find('span.location').html(data[i].location || 'Not set');
                    $containerList.append($templateListClone);
                }

                // Pagination
                var pageCurrent = Number(metaPg.current_page);
                var pageTotal = Number(metaPg.total_pages);
                var pagePrev = metaPg.links.previous || 1;
                var pageNext = metaPg.links.next || pageTotal;

                $pageWrapper.find('input').val(pageCurrent);
                $pageWrapper.find('.total').text(pageTotal);

                $pageWrapper.find('button.previous').attr('data-page', pagePrev);
                $pageWrapper.find('button.next').attr('data-page', pageNext);

                $pageWrapper.find('button.previous').attr('disabled', !!(pagePrev == pageCurrent));
                $pageWrapper.find('button.next').attr('disabled', !!(pageNext == pageCurrent));
                $pageWrapper.find('input').attr('disabled', pageNext == 1)
                
                $spinnerWrapper.addClass('hidden');
                
                // console.log(pagePrev, pageNext, pageCurrent);

                setTimeout(function() {
                    $('html,body').scrollTop(0);
                }, 100);
            },
            error: function(xhr) {
                console.log(xhr);
                $spinnerWrapper.addClass('hidden');
            }
        });
    };

    // Clear Search
    var clearSearch = function() {
        console.log('clearSearches :>> ');
        $('.checkboxParent').prop('checked',false).prop('indeterminate',false);
        $('.checkboxChild').prop('checked',false).prop('indeterminate',false);
        $main.find('.search-tenant input').val('');
        $pageWrapper.find('input').val(1);
        fetchAPI();
    };

    // Apply Debounce to function
    var debounceDelay = 1000;
    var debounceStoresData = debounce(function() {
        // console.log('debounceStoresData');
        fetchAPI();
    }, debounceDelay);

    // Fetch data onload
    if($main.length) {
        setTimeout(function(){
            debounceStoresData();
        });
    }

    // Populate keyword to search input
    if(!!searchString) {
        console.log('url param : ', searchString);
        $main.find('.search-tenant input').val(searchString);
    }
    
    // Programatically trigger click on checkbox
    if(!!categoryString) {
        if(categoryString.includes(',')) categoryString = categoryString.split(',');
        else categoryString = [categoryString];
        console.log('url param : ', categoryString);
        categoryString.forEach(function(oneCategory){
            $main.find('input[data-slug="'+oneCategory+'"]').click();
        });
    } else {
        categoryString = ['specialty-anchor', 'mini-anchor', 'anchor'];
        categoryString.forEach(function(oneCategory){
            $main.find('input[data-slug="'+oneCategory+'"]').click();
        });
    }

});